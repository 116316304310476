<template>
  <div>
    <div style="position: fixed; width: 200px;min-height: 100vh;background-color: #304156;">
      <div style="color: white;font-weight: bold;text-align:center;padding: 20px;">银行数据资产管理平台</div>
      <div class="no-scroll-bar" style="max-height: calc(100vh - 60px)">
        <app-menu :items="menuItems" />
      </div>
    </div>
    
    <div style="margin-left: 200px;">
      <div class="nav-bar">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="o in breadcrumb" :key="o">{{o}}</el-breadcrumb-item>
        </el-breadcrumb>

        <el-dropdown @command="handleCommand($event)">
          <span class="el-dropdown-link" style="cursor:pointer;">
            {{accountInfo?.user?.name}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="updatePassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div style="padding: 15px;">
        <router-view />
      </div>
    </div>
    <div style="position: fixed; bottom: 0; left: 200px; right: 0; padding: 10px;border-top: 1px solid #e7eaec; background-color: white;">
      © 2022 江苏云正 v1.0.0 ⋅ 推荐 Chrome, Firefox 浏览器
    </div>

    <update-password-form ref="updatePasswordForm" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import AppMenu from '@/components/Menu';
import menus from '@/menus';
import UpdatePasswordForm from './UpdatePasswordForm';

const menuChildrenIteratee = p => o => {
  const current = _.assign({}, o, {
    breadcrumb: p ? [... p.breadcrumb, o.title] : [o.title],
  });
  const children = _(o.children).map(menuChildrenIteratee(current)).flatten().value()
  return [current, ...children]
}

const menuList = _(menus).map(menuChildrenIteratee()).flatten().value();

const rejectChildren = (list, fn) => {
  return _(list).reject(fn).map(o => {
    const children = rejectChildren(o.children, fn);
    if(!_.size(children)) return _.omit(o, ['children']);
    return _.assign({}, o, { children })
  }).value()
}
const menuItems = rejectChildren(menus, { menuHide: true })


export default {
  components: { AppMenu, UpdatePasswordForm },
  data() {
    return {
      activeName: 'first',
      menuItems,
    }
  },

  computed: {
    breadcrumb() {
      const matched_path = _.last(this.$route.matched).path;
      return _.map(_.find(menuList, { index: matched_path })?.breadcrumb || [], o => _.isFunction(o) ? o(this.$store.state) : o)
    },
    ...mapState(['accountInfo'])
  },
  methods: {
    async logout() {
      try {
        await this.$api.execute('account.logout');
        this.$router.push(`/login?redirect=${this.$route.path}`)
      } catch(e) {
        this.$message.error(e.message);
      }
    },
    updatePassword() {
      this.$refs.updatePasswordForm.show({ }, {
        title: '修改密码',
        success: () => {
          this.$message.success('密码修改成功，请重新登录')
          this.$router.push(`/login?redirect=${this.$route.path}`)
        }
      });
    },
    handleCommand(command) {
      command && this[command] && this[command]()
    },
  }
}
</script>

<style lang="scss">
.no-scroll-bar {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.nav-bar {
  padding: 0 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 1px 4px #00152914;
}
</style>