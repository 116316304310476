<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="400px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="旧密码">
        <el-input type="password" v-model="form.old_password" />
      </el-form-item>
      <el-form-item label="新密码">
        <el-input type="password" v-model="form.new_password" />
      </el-form-item>
      <el-form-item label="新密码确认">
        <el-input type="password" v-model="form.confirm_password"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        await this.$api.execute('account.update_password', _.pick(this.form, ['old_password', 'new_password', 'confirm_password']));

        this.$emit('success')
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>