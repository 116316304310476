export default [

  { title: '数据源管理', index: '/page-1-1' },
  { title: '元数据管理', index: '/page-p2-2', children: [
    { title: '元数据搜索', index: '/page-2-4' },
    { title: '元数据看板', index: '/page-2-5' },
    { title: '业务元数据', index: '/page-2-6' },
    { title: '技术元模型', index: '/page-2-7' },
    { title: '元数据采集', index: '/page-2-8',children: [
      { title: state => state.task?.name, index: '/page-2-8/:id', menuHide: true },
    ] },
  ]},
  { title: '数据质量', index: 'page-p-4', children: [
    { title: '质量模型管理', index: '/page-4-1', children: [
      { title: state => state.dataQa?.name, index: '/page-4-1/:id', menuHide: true },
    ] },
    { title: '数据质量核验', index: '/page-4-4', children: [
      { title: state => state.dataQaCheck?.name, index: '/page-4-4/:id', menuHide: true },
    ] },
  ] },
  { title: '数据分析', index: '/page-6-1' },
  { title: '系统管理', index: '/page-p-5',  children: [
    { title: '用户管理', index: '/page-0-7' },
    { title: '任务管理', index: '/page-0-6', children: [
      { title: state => state.task?.name, index: '/page-0-6/:id', menuHide: true },
    ] },
  ] },
]